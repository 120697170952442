<template>
  <en-table-layout :table-data="tableData.data" @selection-change="selectionChange" :row-key="row => row.id">
    <template slot="toolbar">

      <div class="col"></div>

      <el-select
        v-model="byStatus"
        placeholder="请选择"
        style="width:150px;margin-right: 20px;"
        size="small"
      >
        <el-option
          label="根据卡号"
          value="card_code"
        ></el-option>
        <el-option
          label="根据销售公司"
          value="company"
        ></el-option>
        <el-option
          label="根据业务员名称"
          value="salesman"
        ></el-option>
        <!-- <el-option
          label="根据套餐礼包"
          value="salesman"
        ></el-option> -->
      </el-select>
      <el-input
        style="width: 220px"
        size="small"
        clearable
        v-model="keyword"
        placeholder="请输入关键字进行搜索"
      ></el-input>

      <el-button
        class="ml-3"
        @click="search"
        type="primary"
        size="small"
      >
        搜索
      </el-button>
    </template>

    <template slot="table-columns">
      <el-table-column
        :key="'selection'"
        :reserve-selection="true"
        type="selection"
      />
      <el-table-column
        :key="'code'"
        label="编号"
        width="50"
        fixed="left"
      >
        <template slot-scope="scope">
          {{ code(scope.$index, params) }}
        </template>
      </el-table-column>

      <el-table-column
        :key="'card_code'"
        prop="card_code"
        label="卡号"
      ></el-table-column>

      <el-table-column
        :key="'exchange_items'"
        prop="exchange_items"
        label="套餐礼包"
      >
        <template slot-scope="scope">
          {{ scope.row.exchange_items ? scope.row.exchange_items : '————' }}
        </template>
      </el-table-column>

      <el-table-column
        prop="exchange_time"
        label="兑换时间"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.exchange_time">
            {{ scope.row.exchange_time | unixToDate("yyyy-MM-dd hh:mm") }}
          </template>
          <template v-else>
            ————
          </template>

        </template>
      </el-table-column>

      <el-table-column
        prop="company"
        label="所属销售公司"
      >
        <template slot-scope="scope">
          {{ scope.row.company ? scope.row.company : '————' }}
        </template>
      </el-table-column>

      <el-table-column
        prop="active_salesman"
        label="业务员"
      >
        <template slot-scope="scope">
          {{ scope.row.active_salesman ? scope.row.active_salesman : '————' }}
        </template>
      </el-table-column>
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableData.page_no"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
      :page-size="tableData.page_size"
      :total="tableData.data_total"
    >
    </el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_Card from "@/api/combo-card-shop/card";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import mixin from "./components/mixin";

export default {
  name: "cardGrantOnlineGrant",
  components: {
    EnTableLayout
  },
  mixins: [mixin],
  data() {
    return {
      params: {
        card_id: this.$route.params.card_id,
        status: "3",// 状态；0：未激活，1：已激活，2已禁用，3已兑换，4已过期
        page_no: 1,
        page_size: 20,
      },
      idsList: [],
      tableData: {},
      byStatus: 'card_code',
      keyword: ''
    };
  },
  computed: {
    _params: function() {
      return {...this.params, [this.byStatus]: this.keyword}
    }
  },
  mounted() {
    this.Get_TableData();
  },
  methods: {
    selectionChange(val){
      this.idsList = val.map(i => i.id)
    },
    /**
     * 获取列表数据
     */
    Get_TableData() {
      API_Card.keyList(this._params).then((res) => {
        this.tableData = res;
      });
    },
  },
};
</script>

<style lang="scss">
</style>
